import React, { useState, useEffect } from 'react'
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import FormModal from "components/Modal/FormModal";
import IntlMessages from 'util/IntlMessages'
import OrderForm from './OrderForm'


const AddNewProductForm = () => {

    const [isProductSelected, setIsProductSelected] = useState(false)
    const [isPrescriptionSelected, setIsPrescriptionSelected] = useState(false)

    console.log('===========isPrescriptionSelected=========', isPrescriptionSelected, isProductSelected)

    const dispatch = useDispatch()
    const curdProps = useSelector(state => state.CrudR)
    const { Add_Order_Modal, Loader } = curdProps




    let initialState = {
        loading: false,
        imageUrl: '',
    }
    const [photoList, setPhotoState] = useState(initialState)
    const reset = () => {
        setIsPrescriptionSelected(false)
        setPhotoState({
            loading: false,
            imageUrl: '',
        })
    }

    useEffect(reset, [Add_Order_Modal])



    return (
        <div>
            <Button
                className='gx-pt-0'
                type="primary"
                onClick={() => {
                    dispatch({
                        type: 'Show_Modal',
                        payload: null,
                        ModalName: "Add_Order_Modal"
                    })
                }}
            >
                create order
            </Button>
            <FormModal
                modalTitle={<p className="gx-text-primary">Add Order </p>}
                basicButtonTitle="Reset Password"
                submitButtonText={<IntlMessages id="customer.modal.save" />}
                cancelButtonText={<IntlMessages id="customer.modal.cancel" />}
                apiMethod="POST"
                addApiName="addOrder"
                addApiRequest="AddRecord"
                apiLoader="StartSpinner"
                ModalName="Add_Order_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Add_Order_Modal ? Add_Order_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                width={600}
                className="doctor-profile-modal"
                extraValueObj={{
                    orderType : isPrescriptionSelected ? 'prescription' : 'nonPrescription',
                    prescriptionUrl: photoList.imageUrl
                }}
                recordName="customerOrderList"
            >
                <OrderForm
                    setIsPrescriptionSelected={setIsPrescriptionSelected}
                    isPrescriptionSelected={isPrescriptionSelected}
                    setPhotoState={setPhotoState} photoList={photoList}
                    setIsProductSelected={setIsProductSelected}
                    isProductSelected={isProductSelected}
                />
            </FormModal>
        </div>

    )
}

export default AddNewProductForm

import React from 'react'


import logo from 'assets/images/delimedLogo.png'
import mainImage from 'assets/images/delimed.png'
const Index = () => {
    return (
        <div className="gx-leftAuthSide" style={{
            background:'#6974E3'
        }}>
            <div className="gx-position-relative gx-h-100 gx-d-flex gx-justify-content-center">
                <div className="main-overlay"></div>
                <img src={mainImage} alt="authMap"  className="main-image"/>
                <img src={logo} alt="" style={{position : 'absolute', top : '20px', left : '30px'}}  className="logo-image"/>
            </div>
        </div>
    )
}

export default Index

import React, { useState, useEffect } from 'react'

import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from 'util/IntlMessages'
import { Form, Row, Col, Button, Carousel, Input, InputNumber, Table, Image, Spin } from 'antd';
import Widget from "components/Widget"
import CardVitaleModal from './CardVitaleModal'
import CardInsuranceModal from './CardInsuranceModal'
import { DataRequestAction } from "redux/actions/CommonHttp";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const EditProfile = () => {


    const [openDetail, setOpenDetail] = useState(true)

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [form] = Form.useForm()

    let currency = process.env.REACT_APP_CURRENCY

    const curdProps = useSelector(state => state.CrudR)
    const { Add_NewOrder_Modal, initialValues } = curdProps

    const pageInfoProps = useSelector(state => state.pageInfo)
    const { productImages, arrayTempLinkSpinner, prescriptionDocuments } = pageInfoProps
    console.log('======prescriptionDocuments-=======', prescriptionDocuments,productImages)

    const setInitialStates = () => {

        if (initialValues) {
            let total = 0
            initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct.forEach(item => {
                total = total + (parseFloat(item.productId.price) * parseFloat(item.qty))
            })

        }
        if (initialValues && initialValues.prescriptionUrl && initialValues.prescriptionUrl.length) {

            dispatch(DataRequestAction('POST', 'getArrayTempLink', 'getArrayTempLink', initialValues.prescriptionUrl, 'gettingArrayTempLink', '', ''))
        }
        setOpenDetail(true)
        form.setFieldsValue(initialValues)
    }

    useEffect(setInitialStates, [Add_NewOrder_Modal])



    const { fullName, email, phoneNumber, address, totalPrice } = initialValues || {}
    const dispatch = useDispatch();

    const openNewOrderModal = () => {
        dispatch({
            type: 'Hide_Modal',
            payload: null,
            ModalName: "Add_NewOrder_Modal"
        })
    }




    const RenderCol = ({ title, desc }) => {
        return <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-pl-0 gx-mb-2">
            <div className="gx-d-flex gx-flex-column">
                <label htmlFor="" className="gx-mb-2">{title}</label>
                <p style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px', minHeight: '40px' }}>{desc}</p>
            </div>
        </Col>


    }

    // const renderNonMedicalProducts = () => {
    //     return initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct.map(item => {
    //         return <div className="gx-d-flex gx-justify-content-between">
    //             <span className="gx-flex-1 ">{item.productId.productName}</span>
    //             <span className="gx-flex-1 gx-text-center">{item.qty}</span>
    //             <span className="gx-flex-1 gx-text-center">€{item.productId.price}</span>
    //         </div>
    //     })
    // }
    const calculateTotalPrice = (qty, price) => {
        let quantity = parseFloat(qty)
        let priceItem = parseFloat(price)

        let total = quantity * priceItem

        if (Number.isInteger(parseFloat(total))) {
            return total
        }
        else {
            total = parseFloat(total).toFixed(2)
            return total
        }
    }
    const columns = [
        {
            title: <IntlMessages id="newOrder.addNewOrderModal.productsName" />,
            render: (record) => <span>{record.productId.productName}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="newOrder.addNewOrderModal.qty" />,
            render: (record) => <span>{record.qty}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="newOrder.addNewOrderModal.price" />,
            render: (record) => <span>{currency}{record.productId.price}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="newOrder.addNewOrderModal.totalPrice" />,
            render: (record) => <span>{currency}{calculateTotalPrice(record.qty, record.productId.price)}</span>,
            key: "No.",
        },
    ]
    const calculateNonMedicinePrice = () => {
        let total = 0
        initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct.forEach(item => {
            total = total + (parseFloat(item.productId.price) * parseFloat(item.qty))
        })
        return total
    }

    function onChange(value) {
        console.log('form.getFieldsValue()', value);

    }
    const SampleNextArrow = props => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                style={{
                    ...style,
                }}
                onClick={onClick}
            >
                <RightOutlined />
            </div>
        )
    }
    const SamplePrevArrow = props => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                style={{
                    ...style,



                }}
                onClick={onClick}
            >
                <LeftOutlined />
            </div>
        )
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    }

    /* Limit the number input box to only enter integers */
    const limitNumber = value => {
        if (typeof value === 'string') {
            return !isNaN(Number(value)) ? value.replace(/^(0+)|[^\d]/g, '') : ''
        } else if (typeof value === 'number') {
            return !isNaN(value) ? String(value).replace(/^(0+)|[^\d]/g, '') : ''
        } else {
            return ''
        }
    }

    const calculateMedicinePrice = () => {
        let totalMedicine = totalPrice - calculateNonMedicinePrice()
        console.log('================totalMedicine======', totalMedicine)

        if (Number.isInteger(parseFloat(totalMedicine))) {
            return totalMedicine
        }
        else {
            totalMedicine = parseFloat(totalMedicine).toFixed(2)
            return totalMedicine
        }
    }


    return (
        <div>


            <SimpleModal
                width={!openDetail ? 500 : 1200}
                title={<span style={{ color: '#041649' }} className="gx-font-sans-bold">
                    {<IntlMessages id="newOrder.addNewOrderModal.title" />} 
                    <span className="gx-ml-2">({(initialValues && initialValues.orderFor) ? initialValues.orderFor : 'Self'})</span>
                    </span>}
                modalFor="Add_NewOrder_Modal"
                visible={Add_NewOrder_Modal ? Add_NewOrder_Modal : false}>

                <span></span>



                <Form
                    layout="vertical"
                    name="editProfile"
                    form={form}
                    initialValues={initialValues && initialValues}

                >
                    <Row className="gx-d-flex gx-flex-row ">
                        {!openDetail ? <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-pl-0">
                            {arrayTempLinkSpinner ? <Spin size="large" /> : <Carousel draggable={true} {...settings} arrows={true}>

                                {prescriptionDocuments && prescriptionDocuments.length && prescriptionDocuments.map(item => {
                                    if (item.type === 'pdf') {
                                        return <div className="pdf-container" >
                                            <Document
                                                file={item.file}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                                renderMode="svg"
                                                options={{
                                                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                                    cMapPacked: true,
                                                }}
                                            >
                                                <Page pageNumber={pageNumber} />
                                            </Document>

                                            <div className="gx-d-flex gx-flex-row gx-align-items-center gx-justify-content-between gx-p-2">
                                                <div className="gx-d-flex gx-flex-row">
                                                    {pageNumber < numPages && <button className="gx-mr-3" onClick={() => setPageNumber(pageNumber + 1)}>
                                                        Next page
                                                    </button>}
                                                    {pageNumber > 1 && <button onClick={() => setPageNumber(pageNumber - 1)}>
                                                        Previous page
                                                    </button>}
                                                </div>
                                                <p>Page {pageNumber} of {numPages}</p>
                                                <p></p>
                                            </div>
                                        </div>
                                    }
                                    else {
                                        return <Image
                                            width="100%"
                                            src={item.file}
                                        />
                                    }


                                })}
                            </Carousel>}

                        </Col> :
                            <Col xl={10} lg={10} md={12} sm={24} xs={24} className="gx-pl-0">
                                {arrayTempLinkSpinner ? <Spin size="large" /> : <Carousel draggable={true} {...settings} arrows={true}>

                                    {prescriptionDocuments && prescriptionDocuments.length && prescriptionDocuments.map(item => {
                                        if (item.type === 'pdf') {
                                            // return <div className="pdf-container" >
                                            //     <Document
                                            //         file={item.file}
                                            //         onLoadSuccess={onDocumentLoadSuccess}
                                            //         renderMode="svg"
                                            //         options={{
                                            //             cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                            //             cMapPacked: true,
                                            //         }}
                                            //     >
                                            //         <Page pageNumber={pageNumber} />
                                            //     </Document>

                                            //     <div className="gx-d-flex gx-flex-row gx-align-items-center gx-justify-content-between gx-p-2">
                                            //         <div className="gx-d-flex gx-flex-row">
                                            //             {pageNumber < numPages && <button className="gx-mr-3" onClick={() => setPageNumber(pageNumber + 1)}>
                                            //                 Next page
                                            //             </button>}
                                            //             {pageNumber > 1 && <button onClick={() => setPageNumber(pageNumber - 1)}>
                                            //                 Previous page
                                            //             </button>}
                                            //         </div>
                                            //         <p>Page {pageNumber} of {numPages}</p>
                                            //         <p></p>
                                            //     </div>
                                            // </div>
                                            return <iframe src={item.file} frameborder="0" height="100%" width="100%">
                                            </iframe>
                                        }
                                        else {
                                            return <Image
                                                width="100%"
                                                src={item.file}
                                            />
                                        }


                                    })}
                                </Carousel>}

                            </Col>

                        }
                        {openDetail && <Col xl={14} lg={14} md={12} sm={24} xs={24} className="gx-pr-0">

                            <h5 className="gx-mb-2"><IntlMessages id="newOrder.addNewOrderModal.prescription" /></h5>
                            <Widget title={<IntlMessages id="newOrder.addNewOrderModal.clientInfo" />}>
                                <Row className="gx-d-flex gx-flex-row ">
                                    <RenderCol title={<IntlMessages id="newOrder.addNewOrderModal.name" />} desc={fullName} />
                                    <RenderCol title={<IntlMessages id="newOrder.addNewOrderModal.email" />} desc={email} />
                                    <RenderCol title={<IntlMessages id="newOrder.addNewOrderModal.phone" />} desc={phoneNumber} />
                                    <RenderCol title={<IntlMessages id="newOrder.addNewOrderModal.address" />} desc={address} />
                                    {/* <RenderCol title={<IntlMessages id="newOrder.addNewOrderModal.vitaleCard" />} desc={vitaleCardNumber} />
                                    <RenderCol title={<IntlMessages id="newOrder.addNewOrderModal.insuranceCard" />} desc={insuranceCardNumber} /> */}
                                </Row>

                            </Widget>


                            <Widget title={<IntlMessages id="newOrder.addNewOrderModal.medicine" />}>
                                <Row className="gx-d-flex gx-flex-row gx-justify-content-around">
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Form.Item
                                            name="totalNumberOfProducts"
                                            label={<IntlMessages id="newOrder.addNewOrderModal.noOfProducts" />}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="newOrder.addNewOrderModal.noOfProducts_message" />
                                                },
                                            ]}
                                        >
                                            <Input disabled formatter={limitNumber} parser={limitNumber} />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Form.Item
                                            name="chargeToCustomer"
                                            label={<IntlMessages id="newOrder.addNewOrderModal.chargeToCustomer" />}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="newOrder.addNewOrderModal_message" />
                                                },
                                            ]}
                                        >

                                            <InputNumber disabled style={{ width: '100%' }} precision={2} formatter={value => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} onChange={onChange} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Widget>

                            {initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length > 0 && <Widget title={'Products'}>
                                <Row className="gx-d-flex gx-flex-row gx-justify-content-between gx-align-items-start">
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-pl-0">
                                        <Table size="small" pagination={{
                                            total: 5,
                                            pageSize: 5,
                                            hideOnSinglePage: true
                                        }} bordered={false} columns={columns} dataSource={initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct} />
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '6px' }}>
                                            <h6><IntlMessages id="newOrder.addNewOrderModal.grandTotal" /></h6>
                                            <div className="gx-d-flex gx-justify-content-between">
                                                <span><IntlMessages id="newOrder.addNewOrderModal.medicine" /></span>
                                                {/* <span>{currency}{totalPrice - calculateNonMedicinePrice()}</span> */}
                                                {totalPrice ? <span>{currency}{calculateMedicinePrice()}</span> :
                                                    <span>{currency}{0}</span>
                                                }
                                            </div>
                                            <div className="gx-d-flex gx-justify-content-between">
                                                <span><IntlMessages id="newOrder.addNewOrderModal.nonMedicine" /></span>
                                                <span>{currency}{calculateNonMedicinePrice()}</span>
                                            </div>
                                            <div className="gx-d-flex gx-justify-content-between">
                                                <span><IntlMessages id="newOrder.addNewOrderModal.total" /></span>
                                                {/* <span>{currency}{totalPrice}</span> */}
                                                {totalPrice ? <span>{currency}{totalPrice} </span> : <span>{currency}{calculateNonMedicinePrice()}</span>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Widget>}


                        </Col>}
                    </Row>






                    <Row className="gx-mt-4">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} >
                            <div className={`gx-d-flex ${!openDetail ? 'gx-justify-content-end' : 'gx-justify-content-between'} gx-align-items-center`}>

                                {openDetail && <div className="gx-d-flex" style={{ gap: '10px' }}>

                                    <CardVitaleModal vitaleCardNumber={initialValues && initialValues.vitalCard} />
                                    <CardInsuranceModal insuranceCardNumber={initialValues && initialValues.insuranceCard} />


                                </div>}
                                <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end " style={{ gap: '10px' }}>
                                    <Form.Item className="gx-mb-0 gx-mr-3">
                                        <Button type="default" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() => openNewOrderModal()}>
                                            <IntlMessages id="newOrder.addNewOrderModal.cancel" />
                                        </Button>
                                    </Form.Item>

                                </div>
                            </div>

                        </Col>
                    </Row>
                </Form>
            </SimpleModal>
        </div>
    )
}

export default EditProfile

import React, { useEffect, useState } from "react";
import Widget from "components/Widget";
import IntlMessages from 'util/IntlMessages'
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from 'antd';
import ResetPasswordModal from './components/ResetPasswordModal'
import EditProfile from './components/EditProfile'
import EditWorkingHours from './components/EditWorkingHours'
import Layout from 'components/LayoutForPortal'
import { DataGetAction } from "redux/actions/CommonHttp";
import dummyImage from 'assets/images/dummyImage.jpeg'
import { renderDate,sortDays } from 'utils/commonUseFunction'

const Index = () => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const dispatch = useDispatch()
    const styles = {
        img: {
            width: '50px',
            height: '50px',
            borderRadius: "50%"
        }
    }
    let initialState = {
        loading: false,
        imageUrl: '',
    }
    const [photoList, setPhotoState] = useState(initialState)


    const authProps = useSelector(state => state.auth)
    const { settingsData } = authProps
    const settingsProps = useSelector(state => state.settings)
    const { locale } = settingsProps

    console.log('=========locale========', locale)

    const fetchSettings = () => {


        dispatch(DataGetAction('getProfile', 'getProfile', '', 'gettingProfile'));
        if (settingsData.profileImageUrl) {
            photoList.imageUrl = settingsData.profileImageUrl
        }
    }

    useEffect(fetchSettings, [settingsData.profileImageUrl]);

    const renderDays = (day) => {
        switch (day) {
            case 'monday':
                return 'Lundi'

            case 'tuesday':
                return 'Mardi'
            case 'wednesday':
                return 'Mercredi'
            case 'thursday':
                return 'jeudi'
            case 'friday':
                return 'Vendredi'
            case 'saturday':
                return 'samedi'
            case 'sunday':
                return 'dimanche'
            default:
                return 'nodate'

        }
    }

    const renderTime = (time) => {
        console.log('========mytime======', time)
        if (locale.languageId === 'french') {
            if (time === '24') {
                return '24 Heures'
            }
            else if (time === 'off') {
                return 'Désactivé'
            }
        }
        else {
            if (time === '24') {
                return '24 hours'
            }
            else if (time === 'off') {
                return 'Off'
            }
        }


    }



    const renderWorkingHours = () => {

        let sortedDays = sortDays(settingsData && settingsData.workingHour && settingsData.workingHour.length && settingsData.workingHour )

        console.log('============sortedDays==========',sortedDays)

        return sortedDays && sortedDays.map(item => {
            return <div className="gx-d-flex gx-justify-content-between gx-mb-3 gx-align-items-center gx-w-75">
                   {locale.languageId === 'french' ? <div style={{ flex: 1 }}>{renderDays(item.day)}</div> : <div style={{ flex: 1, textTransform: 'capitalize' }}>{item.day}</div>}

                {item.time === 'custom' ? <div style={{ flex: 1, display: 'flex'}}>
                    <div className="gx-d-flex gx-flex-column"style={{ flex: 1, display: 'flex', marginRight : '20px' }} >
                        <span>Open</span>
                        <span style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '5px', border: '1px solid #ddd' }}>{renderDate(item.to, 'HH:mm')}</span>
                    </div>
                    <div className="gx-d-flex gx-flex-column">
                        <span>Close</span>
                        <span style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '5px', border: '1px solid #ddd' }}>{renderDate(item.from, 'HH:mm')}</span>
                    </div>
                </div> : <div style={{ flex: 1 }}>{renderTime(item.time)}</div>}


            </div>
        })
    }


    return (
        <Layout>
            <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Widget>
                        <div className="gx-d-flex gx-align-items-flex-start gx-justify-content-between">
                            <div className="gx-d-flex">
                                {settingsData && settingsData.profileImageUrl ? <img src={settingsData && settingsData.profileImageUrl} alt="" style={styles.img} /> : <img src={dummyImage} alt="" style={styles.img} />}

                                <div className="details gx-ml-3 gx-d-flex gx-flex-column">
                                    <h4 className="gx-font-sans-bold" style={{ color: '#595959' }}>
                                        <span>{(settingsData && settingsData.fullName)}</span>

                                    </h4>
                                    <ResetPasswordModal />

                                </div>
                            </div>

                            <div>
                                <EditProfile photoList={photoList} setPhotoState={setPhotoState} />
                            </div>

                        </div>
                        <div className="gx-d-flex gx-justify-content-between gx-my-4">
                            <div className="gx-d-flex gx-align-items-center">
                                <span className="icon icon-email gx-text-primary" style={{ fontSize: '20px' }}></span>
                                <div className="details gx-ml-3 gx-d-flex gx-flex-column">
                                    <span style={{ color: '#D2D2D2' }}> <IntlMessages id="settings.profile.email" /></span>
                                    <span style={{ color: '#595959' }}>{(settingsData && settingsData.email) || 'email.yahoo.com'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="gx-d-flex gx-justify-content-between">
                            <div className="gx-d-flex gx-align-items-center">
                                <span className="icon icon-phone gx-text-primary" style={{ fontSize: '20px' }}></span>
                                <div className="details gx-ml-3 gx-d-flex gx-flex-column">
                                    <span style={{ color: '#D2D2D2' }}><IntlMessages id="settings.profile.phone" /></span>
                                    <span style={{ color: '#595959' }}>+{(settingsData && settingsData.phoneNumber) || '2354534324'}</span>
                                </div>
                            </div>


                        </div>
                    </Widget>

                </Col>

                {userInfo.role === 'pharmacy' && <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Widget
                        title={<h4 className="gx-text-capitalize"><IntlMessages id="settings.workingHour" /></h4>}
                        extra={<EditWorkingHours settingsData={settingsData} />}
                    >



                        {renderWorkingHours()}


                    </Widget>

                </Col>}
            </Row>
        </Layout>
    )
}

export default Index

import React, { useState,useEffect } from 'react'
import { useSelector } from 'react-redux'
import FormModal from "components/Modal/EditFormModal";
import IntlMessages from 'util/IntlMessages'
import OrderForm from './OrderForm'


const EditProduct = () => {


    let initialState = {
        loading: false,
        imageUrl: '',
    }
    
    const curdProps = useSelector(state => state.CrudR)
    const { Edit_Product_Modal, Loader, initialValues } = curdProps


   
    const [photoList, setPhotoState] = useState(initialState)

    const updatePhotoOnEdit = () => {
        
        if(Edit_Product_Modal){
            setPhotoState({
                loading: false,
                imageUrl:initialValues && initialValues.productImageUrl,
            })
        }
    }
    
    useEffect(updatePhotoOnEdit, [Edit_Product_Modal])
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return (
        <div>


            <FormModal
                modalTitle={<p className="gx-text-primary"> <IntlMessages id="product.UpdateProductModal.title" /> </p>}
                basicButtonTitle="Reset Password"
                submitButtonText={<IntlMessages id="product.UpdateProductModal.update" />}
                cancelButtonText={<IntlMessages id="product.modal.cancel" />}
                editApiRequest="EditRecord"
                apiLoader="StartSpinner"
                editApiMethod="PUT"
                editApiName="editProduct"
                ModalName="Edit_Product_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Edit_Product_Modal ? Edit_Product_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                initialValues={initialValues}
                width={600}
                className="doctor-profile-modal"
                extraValueObj={{
                    pharmacyId : userInfo._id,
                    productImageUrl : photoList.imageUrl
                }}
                
                recordName="productList"
            >
              
                 <OrderForm initialValues={initialValues} setPhotoState={setPhotoState} photoList={photoList} />
            </FormModal>
        </div>

    )
}

export default EditProduct

import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import Auth from "./Auth";
import Common from './Common'
import Settings from './Settings'
import activities from './Activities'
import AdminDashboard from "./AdminDashboard";
import SignUp from "./SignUp";
import CurdReducer from './CRUDReducer'
import AdminSettings from "./AdminSettings";
import pageInfo from "./pageInfo";
export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonR: Common,
  activities: activities,
  adminDashboard: AdminDashboard,
  signUp: SignUp,
  adminSettings: AdminSettings,
  CrudR: CurdReducer,
  pageInfo : pageInfo
});

import React from "react";
import Widget from "components/Widget";
import NewOrderList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import Layout from 'components/LayoutForPortal'
import SearchBox from 'components/Filters/SearchBox'
import DateFilter from 'components/Filters/DateFilter'
import { intlPlaceholder } from 'util/intlPlaceholder'
import TableAction from 'components/Table/Actions'
import DeleteConfirm from 'components/Modal/DeleteConfirm';
import AddNewOrderModal from './components/AddNewOrderModal'
import { useDispatch } from 'react-redux'
import {renderDate,camelCaseToNormal} from 'utils/commonUseFunction'
import PrescriptionModal from '../RequestedOrder/components/PrescriptionModal'
import CustomerDetailModal from 'components/Modal/CustomerDetailModal'
const Index = () => {

    const dispatch = useDispatch()

    const openCustomerDetailModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Customer_Detail_Modal"
        })
    }


    const columns = [
        {
            title: <IntlMessages id="quotationSend.no" />,
            render: (record) => <span>{record.orderId}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="quotationSend.totalNumberOfProducts" />,
            render: (record) => <span>{record.totalNumberOfProducts}</span>,
            key: "totalNumberOfProducts"
        },
        {
            title: <IntlMessages id="newOrder.customerName" />,
            
            render: (record) => <span className="gx-link gx-text-capitalize" onClick={() => { openCustomerDetailModal(record) }}>{record.fullName}</span>,
            key: "customerName",
            width : 120
        },
        {
            title: <IntlMessages id="quotationSend.address" />,
            render: (record) => <span>{record.address}</span>,
            key: "address"
        },

        {
            title: <IntlMessages id="quotationSend.dateAndTime" />,
            render: (record) => <span>{renderDate(record.createdAt,'MMMM Do YYYY hh:mm:ss a')}</span>,
            key: "date"
        },
        {
            title: <IntlMessages id="requestedOrder.orderType" />,
            render: (record) => <span>{camelCaseToNormal(record.orderType)}</span>,
            key: "date"
        },
        {
            title: <IntlMessages id="newOrder.orderFor" />,
            render: (record) => <span>{camelCaseToNormal(record.orderFor ? record.orderFor : 'Self' )}</span>,
            key: "date"
        },
        {
            title: <IntlMessages id="quotationSend.status" />,
            render: (record) => <span className="gx-text-primary">Quotation Sent</span>,
            key: "status"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },

    ];
    const renderActions = (record) => {
        
        return <div className="gx-d-flex gx-flex-column">
            {record.orderType !== 'nonPrescription' &&  <span style={{ cursor: 'pointer' }} onClick={()=>{openNewOrderModal(record)}}><IntlMessages id="quotationSend.actions.view" /></span>}
            {record.orderType === 'nonPrescription' && <span style={{ cursor: 'pointer' }}  onClick={()=>{openPrescriptionModal(record)}} ><IntlMessages id="requestedOrder.actions.view" /></span>}
            <DeleteConfirm
                type="cancelOrder"
                recordName={'quotationSentList'}
                method="PUT"
                selectedItem={record}
                apiRequest="DeleteRecord"
                buttonName="Yes"
                cancelText={intlPlaceholder('confirmationModal.cancel')} 
                oKText={intlPlaceholder('confirmationModal.yes')}
                title={intlPlaceholder('quotationSend.actions.cancel_text')} 
                content={intlPlaceholder('quotationSend.actions.cancel_content')} 
                Icon={<span style={{ fontSize: '14px' }}><IntlMessages id="quotationSend.actions.cancel" /></span>}
            />
            
          
        </div>
    }

    const openNewOrderModal = (record) =>{
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Add_NewOrder_Modal"
        })
    }
    const openPrescriptionModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Add_Prescription_Modal"
        })
    }

    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="quotationSend.pageTitle" />
                    </h4>
                }
                extra={
                    <div className="gx-d-flex">
                        <div style={{ width: '240px', marginRight: '12px' }}>
                            <DateFilter query="status" customQuery={{ value : 'quotationSent'}} placeholder={[intlPlaceholder('quotationSend.filter.startDate'), intlPlaceholder('quotationSend.filter.endDate')]} apiName="getOrder" requestType="FetchRecord" recordName="quotationSentList" />
                        </div>
                        <div style={{ width: '220px', marginRight: '12px' }}>
                            <SearchBox recordName="quotationSentList" placeholder={intlPlaceholder('quotationSend.filter.productNameOrderId')} apiName="getOrder" customQuery={{query : 'status', value : 'quotationSent'}}  requestType="FetchRecord" />
                        </div>
                    </div>
                }
            >

                <NewOrderList query={'status'} customQuery={{value : 'quotationSent'}}  recordName="quotationSentList" apiName="getOrder" columns={columns} />
            </Widget>
            <AddNewOrderModal/>
            <PrescriptionModal/>
            <CustomerDetailModal />
        </Layout>
    );
};

export default Index;
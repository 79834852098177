

  export const setInitUrl = (url) => {
    return {
      type: 'INIT_URL',
      payload: url
    };
  };
  

  
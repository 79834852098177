import React from 'react'
import LoginForm from 'components/Auth/Signin'
import InfoAuthLeftSide from 'components/Auth/InfoAuthLeftSide'
import { Row, Col } from 'antd';
const Login = () => {
    return (
        <div className="gx-authBackGround">
            <Row>
                <Col xl={12} lg={12} sm={12} md={12} className="gx-d-none gx-d-md-block">
                    <InfoAuthLeftSide />
                </Col>
                <Col xl={12} lg={12} sm={24} md={24} className="gx-d-flex gx-flex-column gx-justify-content-center">
                    <LoginForm />
                </Col>
            </Row>
        </div>
    )
}

export default Login

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from 'redux/actions/CommonHttp'
import { Spin, Button ,Modal} from 'antd'
import IntlMessages from 'util/IntlMessages'
const VitalCardModal = ({ vitaleCardNumber }) => {


    const dispatch = useDispatch();
    

    const pageProps = useSelector(state => state.pageInfo)
    const { cardLink, startLoader, vitaleModal ,tempLinkSpinner} = pageProps

 
    console.log('=========startLoader', startLoader)
    console.log('=========cardLink', startLoader)
    const fetchImage = () => {

            if(vitaleModal && vitaleCardNumber){
                
                dispatch(DataRequestAction('POST', 'getTempLink', 'getTempLink', { 'image': vitaleCardNumber }, 'gettingTempLink'))
            }
            
  
    }
    useEffect(fetchImage, [vitaleModal])
    const showCard = () => {
        dispatch({
            type: 'Toggle_Image_Modal',
            payload: vitaleCardNumber,
        })
    }

    const handleCancel = () => {
        dispatch({
            type: 'Toggle_Image_Modal',
            payload: null,
        })
    }

    return (
        <div>
            <Button type="primary" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() => showCard(vitaleCardNumber)}>
            <IntlMessages id="newOrder.addNewOrderModal.cardVitale" />
                                        </Button>
            <Modal onCancel={handleCancel}
                width={500}
                visible={vitaleModal}
                footer={false}>
                {tempLinkSpinner ? <Spin /> : vitaleCardNumber ?     <img src={cardLink} className="gx-mt-5" width="100%" height="400px" alt="" /> : ''}
                {!vitaleCardNumber && <span> <IntlMessages id="imageModal.noDataFound" /></span>}
            </Modal>
        </div>
    )
}

export default VitalCardModal

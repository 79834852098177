import React from "react";
import Widget from "components/Widget";
import NewOrderList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import Layout from 'components/LayoutForPortal'
import SearchBox from 'components/Filters/SearchBox'
import DateFilter from 'components/Filters/DateFilter'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { useDispatch } from 'react-redux'
import AddNewOrderModal from './components/AddNewOrderModal'
import PrescriptionModal from './components/PrescriptionModal'
import { renderDate,camelCaseToNormal } from 'utils/commonUseFunction'
import TableAction from 'components/Table/Actions'
import DeleteConfirm from 'components/Modal/DeleteConfirm';
import CustomerDetailModal from 'components/Modal/CustomerDetailModal'
const Index = () => {




    const dispatch = useDispatch()

    const openCustomerDetailModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Customer_Detail_Modal"
        })
    }

    const columns = [
        {
            title: <span><IntlMessages id="newOrder.no" /></span>   ,
            render: (record) => <span >{record.orderId}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="newOrder.customerName" />,
            
            render: (record) => <span className="gx-link gx-text-capitalize" onClick={() => { openCustomerDetailModal(record) }}>{record.fullName}</span>,
            key: "customerName",
            width : 120
        },


        {
            title: <IntlMessages id="newOrder.address" />,
            render: (record) => <span>{record.address}</span>,
            key: "address"
        },

       
        {
            title: <IntlMessages id="newOrder.dateAndTime" />,
            render: (record) => {return <div >
                <div>{renderDate(record.createdAt,'MMMM Do YYYY')}</div>
                <div>{renderDate(record.createdAt,'hh:mm:ss a')}</div>
                </div>
        
        },
            key: "date",
            width : 150
        },
        {
            title: <IntlMessages id="newOrder.orderType" />,
            render: (record) => <span>{camelCaseToNormal(record.orderType)}</span>,
            key: "date"
        },
        {
            title: <IntlMessages id="newOrder.orderFor" />,
            render: (record) => <span>{camelCaseToNormal(record.orderFor ? record.orderFor : 'Self' )}</span>,
            key: "date"
        },
        {
            title: <IntlMessages id="newOrder.status" />,
            render: (record) => {
                if(record.orderType === 'nonPrescription'){
                    return <span className="gx-link" onClick={() => { openPrescriptionModal(record) }}><IntlMessages id="newOrder.view" /></span>
                }
                else{
                    return <span className="gx-link" onClick={() => { openNewOrderModal(record) }}><IntlMessages id="newOrder.view" /></span>
                }
            
        },
            key: "view"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },

    ];

    const openNewOrderModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Add_NewOrder_Modal"
        })
    }
    const openPrescriptionModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Add_Prescription_Modal"
        })
    }

    const renderActions = (record) => {

        return <div className="gx-d-flex gx-flex-column">

            <DeleteConfirm
                type="cancelOrder"
                recordName={'newOrderList'}
                method="PUT"
                selectedItem={record}
                apiRequest="DeleteRecord"
                buttonName="Yes"
                cancelText={intlPlaceholder('confirmationModal.cancel')}
                oKText={intlPlaceholder('confirmationModal.yes')}
                title={intlPlaceholder('quotationSend.actions.cancel_text')}
                content={intlPlaceholder('quotationSend.actions.cancel_content')}
                Icon={<span style={{ fontSize: '14px' }}><IntlMessages id="quotationSend.actions.cancel" /></span>}
            />
            {record.orderType === 'nonPrescription' && <DeleteConfirm
                type="createNonMedicalOrder"
                recordName={'newOrderList'}
                method="PUT"
                selectedItem={record}
                apiRequest="DeleteRecord"
                buttonName="Yes"
                cancelText={intlPlaceholder('confirmationModal.cancel')}
                oKText={intlPlaceholder('confirmationModal.yes')}
                title={intlPlaceholder('newOrder.actions.accept_text')}
                content={intlPlaceholder('newOrder.actions.accept_content')}
                Icon={<span style={{ fontSize: '14px' }}><IntlMessages id="newOrder.actions.accept" /></span>}
            />}


        </div>
    }


    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="newOrder.pageTitle" />
                    </h4>
                }
                extra={
                    <div className="gx-d-flex">
                        <div style={{ width: '240px', marginRight: '12px' }}>
                            <DateFilter placeholder={[intlPlaceholder('newOrder.filter.startDate'), intlPlaceholder('newOrder.filter.endDate')]} query="status" customQuery={{ value: 'newOrder' }} apiName="getOrder" requestType="FetchRecord" recordName="newOrderList" />
                        </div>
                        <div style={{ width: '220px', marginRight: '12px' }}>
                            <SearchBox recordName="newOrderList" placeholder={intlPlaceholder('newOrder.filter.productNameOrderId')} apiName="getOrder" customQuery={{ query: 'status', value: 'newOrder' }} requestType="FetchRecord" />
                        </div>
                    </div>
                }
            >

                <NewOrderList query={'status'} customQuery={{ value: 'newOrder' }} recordName="newOrderList" apiName="getOrder" columns={columns} />
            </Widget>
            <AddNewOrderModal />
            <PrescriptionModal />
            <CustomerDetailModal />
        </Layout>
    );
};

export default Index;
import React from 'react'
import { Select } from 'antd'
import { DataGetAction } from 'redux/actions/CommonHttp'
import { useDispatch } from 'react-redux'
import IntlMessages from 'util/IntlMessages'
const CallFilters = ({ showAll, placeholder, marginRight, options, ItemValue,searchId, label, apiName, searchKey, isShowingAll,className ,defaultValue,query="search",recordName}) => {

    const dispatch = useDispatch();
    const handleChange = (data) => {
        const finalQuery = { query: query, "key": searchKey, "value": data };
        if (data === "all") {
            finalQuery.query = "all";
            if(showAll){
                return showAll()
            }
        }
        
        dispatch(DataGetAction(apiName,'FetchRecord', finalQuery, "searching",'',recordName))
    }


    return (
        <Select placeholder={placeholder} onChange={(e) => { handleChange(e) }} defaultValue={defaultValue} className={className} style={{ width: '200px', marginRight: marginRight }} >
            {isShowingAll && <Select.Option value="all"><IntlMessages id="filter.all" /></Select.Option> }
            {options && options.map(Item => {

                return <Select.Option value={Item[`${ItemValue}`]}>{Item[`${label}`]}</Select.Option>
            })}
        </Select>
    )
}

export default CallFilters

import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { Spin, Button, Modal } from "antd";
import IntlMessages from "util/IntlMessages";
const VitalCardModal = ({ vitaleCardNumber }) => {
  const dispatch = useDispatch();

  const pageProps = useSelector((state) => state.pageInfo);
  const { cardLink, startLoader, vitaleModal, productImages } = pageProps;

  console.log("=========startLoader", startLoader);
  console.log("=========cardLink", startLoader);
  const fetchImage = () => {
    if (vitaleModal) {
      dispatch(
        DataRequestAction(
          "POST",
          "getTempLink",
          "getTempLink",
          { image: vitaleCardNumber },
          "StartLoader"
        )
      );
    }
  };
  useEffect(fetchImage, [vitaleModal]);
  const showCard = () => {
    dispatch({
      type: "Toggle_Image_Modal",
      payload: vitaleCardNumber,
    });
  };

  const handleCancel = () => {
    dispatch({
      type: "Toggle_Image_Modal",
      payload: null,
    });
  };

  return (
    <div>
      <Button
        type="primary"
        className="gx-pt-0 gx-mb-0 gx-mr-2"
        htmlType="button"
        onClick={() => showCard(vitaleCardNumber)}
      >
        <IntlMessages id="newOrder.addNewOrderModal.cardVitale" />
      </Button>
      <Modal
        onCancel={handleCancel}
        width={500}
        visible={vitaleModal}
        footer={false}
      >
        <>
          {startLoader ? <Spin /> : <img src={cardLink} alt="" />}
          {!vitaleCardNumber && (
            <span>
              <IntlMessages id="imageModal.noDataFound" />
            </span>
          )}
        </>
      </Modal>
    </div>
  );
};

export default VitalCardModal;

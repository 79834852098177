import React from "react";
import { Route, Switch } from "react-router-dom";
import RestPassword from './Auth/restPassword'
import Category from'./Pharmacy/Category'
import Employees from './Pharmacy/Employees'
import PharmacyDashboard from './Pharmacy'
import EmployeeDashboard from './Employee'
import CustomerOrder from './Customer'
import Settings from './Pharmacy/Setting'
import Product from './Pharmacy/Product'
import NewOrder from './Pharmacy/Order/NewOrder'
import QuotationSent from './Pharmacy/Order/QuotationSent'
import RequestedOrder from './Pharmacy/Order/RequestedOrder'
import InProcess from './Pharmacy/Order/InProcess'
import Completed from './Pharmacy/Order/Completed'
import Cancelled from './Pharmacy/Order/Cancelled'
//import User from "cotter/lib/models/User";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {/* <Route path={`${match.url}home`} component={Home}/> */}

      <Route exact path={`${match.url}dashboard`} component={PharmacyDashboard} />
      <Route exact path={`${match.url}employeeDashboard`} component={EmployeeDashboard} />
      <Route exact path={`${match.url}customerOrder`} component={CustomerOrder} />
      <Route path={`${match.url}resetPassword`} component={RestPassword} />
      <Route path={`${match.url}admin/resetPassword`} component={RestPassword} />
      <Route path={`${match.url}admin/resetPassword`} component={RestPassword} />
      <Route path={`${match.url}product`} component={Product} />
      <Route path={`${match.url}employees`} component={Employees} />
      <Route path={`${match.url}order/newOrder`} component={NewOrder} />
      <Route path={`${match.url}order/quotationSend`} component={QuotationSent} />
      <Route path={`${match.url}order/requestedOrder`} component={RequestedOrder} />
      <Route path={`${match.url}order/inProcess`} component={InProcess} />
      <Route path={`${match.url}order/completed`} component={Completed} />
      <Route path={`${match.url}order/cancelled`} component={Cancelled} />
      <Route path={`${match.url}category`} component={Category} />
      <Route exact path={`${match.url}settings`} component={Settings} />
    </Switch>
  </div>
);

export default App;

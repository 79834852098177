import React from "react";
import Widget from "components/Widget";
import NewOrderList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import Layout from 'components/LayoutForPortal'
import SearchBox from 'components/Filters/SearchBox'
import DateFilter from 'components/Filters/DateFilter'
import { intlPlaceholder } from 'util/intlPlaceholder'
import TableAction from 'components/Table/Actions'
import { renderDate ,camelCaseToNormal} from 'utils/commonUseFunction'
import AddNewOrderModal from '../QuotationSent/components/AddNewOrderModal'
import { useDispatch } from 'react-redux'
import PrescriptionModal from '../RequestedOrder/components/PrescriptionModal'
import CustomerDetailModal from 'components/Modal/CustomerDetailModal'
const Index = () => {
    const dispatch = useDispatch()
    const openCustomerDetailModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Customer_Detail_Modal"
        })
    }
    const columns = [
        {
            title: <IntlMessages id="inProcessOrder.no" />,
            render: (record) => <span>{record.orderId}</span>,
            key: "No.",
        },
        
        {
            title: <IntlMessages id="inProcessOrder.qty" />,
            render: (record) => <span>{record.totalNumberOfProducts ? record.totalNumberOfProducts : '---'}</span>,
            key: "qty"
        },
        {
            title: <IntlMessages id="inProcessOrder.customerName" />,
            
            render: (record) => <span className="gx-link gx-text-capitalize" onClick={() => { openCustomerDetailModal(record) }}>{record.fullName}</span>,
            key: "customerName",
            width : 120
        },
        
        
       
        {
            title: <IntlMessages id="inProcessOrder.dateAndTime" />,
            render: (record) => {return <div >
                <div>{renderDate(record.createdAt,'MMMM Do YYYY')}</div>
                <div>{renderDate(record.createdAt,'hh:mm:ss a')}</div>
                </div>
        
        },
            key: "date",
            width : 150
        },
        {
            title: <IntlMessages id="requestedOrder.orderType" />,
            render: (record) => <span>{camelCaseToNormal(record.orderType)}</span>,
            key: "date"
        },
        {
            title: <IntlMessages id="inProcessOrder.status" />,
            render: (record) => <span className="gx-text-primary">{camelCaseToNormal(record.status)}</span>,
            key: "status"
        },
        {
            title: <IntlMessages id="inProcessOrder.deliveryMode" />,
            render: (record) => <span className="gx-text-primary">{camelCaseToNormal(record.deliveryMode)}</span>,
            key: "status"
        },
        {
            title: <IntlMessages id="newOrder.orderFor" />,
            render: (record) => <span>{camelCaseToNormal(record.orderFor ? record.orderFor : 'Self' )}</span>,
            key: "date"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },

    ];
    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
           {record.orderType !== 'nonPrescription' && <span style={{ cursor: 'pointer' }}  onClick={()=>{openNewOrderModal(record)}}><IntlMessages id="inProcessOrder.actions.view" /></span>}
            {record.orderType === 'nonPrescription' && <span style={{ cursor: 'pointer' }}  onClick={()=>{openPrescriptionModal(record)}} ><IntlMessages id="requestedOrder.actions.view" /></span>}
          
            
        </div>
    }
   
    const openNewOrderModal = (record) =>{
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Add_NewOrder_Modal"
        })
    }
    const openPrescriptionModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Add_Prescription_Modal"
        })
    }
    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="inProcessOrder.pageTitle" />
                    </h4>
                }
                extra={
                    <div className="gx-d-flex">
                        <div style={{ width: '240px', marginRight: '12px' }}>
                            <DateFilter query="status" customQuery={{ value : 'riderPicked'}} placeholder={[intlPlaceholder('inProcessOrder.filter.startDate'), intlPlaceholder('inProcessOrder.filter.endDate')]} apiName="getOrder" requestType="FetchRecord" recordName="inProcessOrderList" />
                        </div>
                        <div style={{ width: '220px', marginRight: '12px' }}>
                            <SearchBox customQuery={{query : 'status', value : 'riderPicked'}} recordName="inProcessOrderList" placeholder={intlPlaceholder('inProcessOrder.filter.productOrderEmail')} apiName="getOrder" requestType="FetchRecord" />
                        </div>
                    </div>
                }
            >

                <NewOrderList query={'status'} customQuery={{ value: 'riderPicked' }} recordName="inProcessOrderList" apiName="getOrder" columns={columns} />
            </Widget>
            <AddNewOrderModal/>
            <PrescriptionModal/>
            <CustomerDetailModal />
        </Layout>
    );
};

export default Index;
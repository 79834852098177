import React, { useEffect } from 'react'

import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from 'redux/actions/CommonHttp'
import { Spin, Button ,Modal} from 'antd'
import IntlMessages from 'util/IntlMessages'
const VitalCardModal = ({ insuranceCardNumber }) => {


    const dispatch = useDispatch();


    const pageProps = useSelector(state => state.pageInfo)
    const { cardLink, startLoader, insuranceModal,tempLinkSpinner } = pageProps

 
    console.log('=========startLoader', startLoader)
    console.log('=========cardLink', cardLink)
    const fetchImage = () => {

            if(insuranceModal && insuranceCardNumber){
                
                dispatch(DataRequestAction('POST', 'getTempLink', 'getTempLink', { 'image': insuranceCardNumber }, 'gettingTempLink'))
            }
            
            
    }
    useEffect(fetchImage, [insuranceModal])
    const showCard = () => {
        dispatch({
            type: 'Toggle_Insurance_Modal',
            payload: insuranceCardNumber,
        })
    }

    const handleCancel = () => {
        dispatch({
            type: 'Toggle_Insurance_Modal',
            payload: null,
        })
    }

    return (
        <div>
            <Button type="primary" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() => showCard(insuranceCardNumber)}>
            <IntlMessages id="newOrder.addNewOrderModal.cardInsurance" />
                                        </Button>
            <Modal   
                onCancel={handleCancel}
                width={500}
                visible={insuranceModal}
                footer={false}>
                {tempLinkSpinner ? <Spin /> : insuranceCardNumber ? <img src={cardLink} className="gx-mt-5" width="100%" height="400px" alt="" /> : ''}
                {!insuranceCardNumber && <span> <IntlMessages id="imageModal.noDataFound" /></span>}
            </Modal>
        </div>
    )
}

export default VitalCardModal

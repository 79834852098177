import React from "react";
import Widget from "components/Widget";
import NewOrderList from "components/Table/List";
import IntlMessages from "util/IntlMessages";
import Layout from "components/LayoutForPortal";
import SearchBox from "components/Filters/SearchBox";
import DateFilter from "components/Filters/DateFilter";
import { intlPlaceholder } from "util/intlPlaceholder";
import TableAction from "components/Table/Actions";
import { renderDate, camelCaseToNormal } from "utils/commonUseFunction";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import AddNewOrderModal from "./components/AddNewOrderModal";
import PrescriptionModal from "./components/PrescriptionModal";
import { useDispatch } from "react-redux";
import CustomerDetailModal from "components/Modal/CustomerDetailModal";
const Index = () => {
  const dispatch = useDispatch();

  const openCustomerDetailModal = (record) => {
    dispatch({
      type: "Show_Modal",
      payload: record,
      ModalName: "Customer_Detail_Modal",
    });
  };

  const getTotalPrice = (totalPrice, deliveryCost, deliveryMode) => {
    if (deliveryMode === "rider") {
      let total = parseFloat(totalPrice) - parseFloat(deliveryCost);
      total = total.toFixed(2);

      return total;
    } else {
      let total = parseFloat(totalPrice);
      total = total.toFixed(2);

      return total;
    }
  };
  const columns = [
    {
      title: <IntlMessages id="requestedOrder.no" />,
      render: (record) => <span>{record.orderId}</span>,
      key: "No.",
      width: 50,
    },
    {
      title: <IntlMessages id="requestedOrder.orderAmount" />,
      render: (record) =>
        record.totalPrice ? (
          <span>
            {getTotalPrice(
              record.totalPrice,
              record.deliveryCost,
              record.deliveryMode
            )}
          </span>
        ) : (
          "----"
        ),
      key: "orderAmount",
    },
    {
      title: <IntlMessages id="newOrder.customerName" />,

      render: (record) => (
        <span
          className="gx-link gx-text-capitalize"
          onClick={() => {
            openCustomerDetailModal(record);
          }}
        >
          {record.fullName}
        </span>
      ),
      key: "customerName",
      width: 120,
    },
    {
      title: <IntlMessages id="requestedOrder.address" />,
      render: (record) => <span>{record.address}</span>,
      key: "address",
      width: 180,
    },

    {
      title: <IntlMessages id="requestedOrder.dateAndTime" />,
      render: (record) => {
        return (
          <div>
            <div>{renderDate(record.createdAt, "MMMM Do YYYY")}</div>
            <div>{renderDate(record.createdAt, "hh:mm:ss a")}</div>
          </div>
        );
      },
      key: "date",
      width: 120,
    },
    {
      title: <IntlMessages id="requestedOrder.orderType" />,
      render: (record) => <span>{camelCaseToNormal(record.orderType)}</span>,
      key: "date",
    },

    {
      title: <IntlMessages id="requestedOrder.status" />,
      render: (record) => (
        <span className="gx-text-primary">
          {camelCaseToNormal(record.paymentStatus)}
        </span>
      ),
      key: "status",
    },
    {
      title: <IntlMessages id="newOrder.orderFor" />,
      render: (record) => (
        <span>
          {camelCaseToNormal(record.orderFor ? record.orderFor : "Self")}
        </span>
      ),
      key: "date",
    },
    {
      title: <IntlMessages id="requestedOrder.deliveryMode" />,
      render: (record) =>
        record.deliveryMode === "rider" ? (
          <span className="gx-text-primary gx-d-flex gx-flex-column">
            <span>{camelCaseToNormal(record.deliveryMode)}</span>

            <span className="gx-ml-1">
              {renderDate(record.scheduleTime, "MMMM Do YYYY ")}
            </span>
            <span className="gx-ml-1">
              {renderDate(record.scheduleTime, "hh:mm:ss a")}
            </span>
          </span>
        ) : (
          <span className="gx-text-primary">
            {camelCaseToNormal(record.deliveryMode)}
          </span>
        ),
      key: "status",
      width: 150,
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
      key: "action",
    },
  ];
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        {record.orderType !== "nonPrescription" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              openNewOrderModal(record);
            }}
          >
            <IntlMessages id="requestedOrder.actions.view" />
          </span>
        )}
        {record.orderType === "nonPrescription" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              openPrescriptionModal(record);
            }}
          >
            <IntlMessages id="requestedOrder.actions.view" />
          </span>
        )}

        {record.orderType === "nonPrescription" && (
          <DeleteConfirm
            type="cancelOrder"
            recordName={"requestedOrderList"}
            method="PUT"
            selectedItem={record}
            apiRequest="DeleteRecord"
            buttonName="Yes"
            cancelText={intlPlaceholder("confirmationModal.cancel")}
            oKText={intlPlaceholder("confirmationModal.yes")}
            title={intlPlaceholder("requestedOrder.actions.cancel_text")}
            content={intlPlaceholder("requestedOrder.actions.cancel_content")}
            Icon={
              <span style={{ fontSize: "14px" }}>
                <IntlMessages id="requestedOrder.actions.cancel" />
              </span>
            }
          />
        )}
        {(record.deliveryMode === "selfPickup" ||
          record.deliveryMode === "selfPick") && (
          <DeleteConfirm
            type="completeOrder"
            recordName={"requestedOrderList"}
            method="PUT"
            selectedItem={record}
            apiRequest="DeleteRecord"
            buttonName="Yes"
            cancelText={intlPlaceholder("confirmationModal.cancel")}
            oKText={intlPlaceholder("confirmationModal.yes")}
            title={intlPlaceholder("requestedOrder.actions.processed_text")}
            content={intlPlaceholder(
              "requestedOrder.actions.processed_content"
            )}
            Icon={
              <span style={{ fontSize: "14px" }}>
                <IntlMessages id="requestedOrder.actions.processed" />
              </span>
            }
          />
        )}
        {record.deliveryMode === "rider" && (
          <DeleteConfirm
            type="deliverToRider"
            recordName={"requestedOrderList"}
            method="PUT"
            selectedItem={record}
            apiRequest="DeleteRecord"
            buttonName="Yes"
            cancelText={intlPlaceholder("confirmationModal.cancel")}
            oKText={intlPlaceholder("confirmationModal.yes")}
            title={intlPlaceholder(
              "requestedOrder.actions.deliverToRider_text"
            )}
            content={intlPlaceholder(
              "requestedOrder.actions.deliverToRider_content"
            )}
            Icon={
              <span style={{ fontSize: "14px" }}>
                <IntlMessages id="requestedOrder.actions.deliverToRider" />
              </span>
            }
          />
        )}
      </div>
    );
  };

  const openNewOrderModal = (record) => {
    dispatch({
      type: "Show_Modal",
      payload: record,
      ModalName: "Add_NewOrder_Modal",
    });
  };
  const openPrescriptionModal = (record) => {
    dispatch({
      type: "Show_Modal",
      payload: record,
      ModalName: "Add_Prescription_Modal",
    });
  };
  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title={
          <h4
            className="gx-mb-0 gx-font-gilory-bold"
            style={{ color: "#14244E" }}
          >
            <IntlMessages id="requestedOrder.pageTitle" />
          </h4>
        }
        extra={
          <div className="gx-d-flex">
            <div style={{ width: "240px", marginRight: "12px" }}>
              <DateFilter
                query="status"
                customQuery={{ value: "requestedOrder" }}
                placeholder={[
                  intlPlaceholder("requestedOrder.filter.startDate"),
                  intlPlaceholder("requestedOrder.filter.endDate"),
                ]}
                apiName="getOrder"
                requestType="FetchRecord"
                recordName="requestedOrderList"
              />
            </div>
            <div style={{ width: "220px", marginRight: "12px" }}>
              <SearchBox
                recordName="requestedOrderList"
                customQuery={{ query: "status", value: "requestedOrder" }}
                placeholder={intlPlaceholder(
                  "requestedOrder.filter.orderIDAmount"
                )}
                apiName="getOrder"
                requestType="FetchRecord"
              />
            </div>
          </div>
        }
      >
        <NewOrderList
          query={"status"}
          customQuery={{ value: "requestedOrder" }}
          recordName="requestedOrderList"
          apiName="getOrder"
          columns={columns}
        />
      </Widget>
      <AddNewOrderModal />
      <PrescriptionModal />
      <CustomerDetailModal />
    </Layout>
  );
};

export default Index;

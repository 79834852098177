import React from 'react'
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import FormModal from "../../../../components/Modal/FormModal";
import IntlMessages from '../../../../util/IntlMessages'
import CategoryForm from './CategoryForm'


const AddNewCategory = () => {
    
    const dispatch = useDispatch()
    const curdProps = useSelector(state => state.CrudR)
    const { AddCategory_Modal, Loader } = curdProps
   
    return (
        <div>
            <Button
                className='gx-pt-0'
                type="primary"
                onClick={() => {
                    dispatch({
                        type: 'Show_Modal',
                        payload: null,
                        ModalName: "AddCategory_Modal"
                    })
                }}
            >
                <IntlMessages id="category.addNewButton.label" />
            </Button>
            <FormModal
                modalTitle={<p className="gx-text-primary"> <IntlMessages id="category.modal.title" /> </p>}
                basicButtonTitle="Reset Password"
                submitButtonText={<IntlMessages id="category.modelAddButton.label" />}
                cancelButtonText={<IntlMessages id="category.modelCancelButton.label" />}
                apiMethod="POST"
                addApiName="addCategory"
                addApiRequest="AddRecord"
                apiLoader="StartSpinner"
                ModalName="AddCategory_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={AddCategory_Modal ? AddCategory_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                width={520}
                className="doctor-profile-modal"
                recordName={'categoryList'}
            >
                <CategoryForm />
            </FormModal>
        </div>

    )
}

export default AddNewCategory

import React, { useEffect } from "react";
import Widget from "components/Widget";
import OrderList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import TableAction from 'components/Table/Actions'
import Layout from 'components/LayoutForPortal'
import SelectFilter from 'components/Filters/SelectFilter'
import SearchBox from 'components/Filters/SearchBox'
import AddOrderForm from './components/AddOrderForm'
import { useDispatch, useSelector } from 'react-redux'
import EditOrder from './components/EditOrder'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { DataGetAction } from 'redux/actions/CommonHttp';
import DeleteConfirm from 'components/Modal/DeleteConfirm';
const Index = () => {

    const dispatch = useDispatch()
    const fetchList = () => {

        dispatch(DataGetAction('getCategory', 'getCategory', { query: 'all' }, '', '', 'allCategories'))

    }

    useEffect(fetchList, [])


    const pageProps = useSelector(state => state.pageInfo)
    const { allCategories } = pageProps
    const columns = [
        {
            title: 'Order ID',
            render: (record) => <span>{record.orderId}</span>,
            key: "id",
        },

        {
            title: 'Customer name',
            render: (record) => <span>{record.fullName}</span>,
            key: "doctorName",
        },

        {
            title: <IntlMessages id="customer.email" />,
            render: (record) => <span>{record.email}</span>,
            key: "email"
        },
        {
            title: 'phoneNumber',
            render: (record) => <span>{record.phoneNumber}</span>,
            key: "phoneNumber"
        },
        {
            title:'paymentStatus',
            render: (record) => <span>{record.paymentStatus}</span>,
            key: "paymentStatus"
        },
        {
            title: 'insuranceCardNumber',
            render: (record) => <span>{record.insuranceCardNumber} </span>,
            key: "insuranceCardNumber"
        },
        {
            title: 'vitaleCardNumber',
            render: (record) => <span>{record.vitaleCardNumber} </span>,
            key: "vitaleCardNumber"
        },
        {
            title: 'prescriptionUrl',
            render: (record) => <img src={record.prescriptionUrl} style={{width : '50px', height : '50px', borderRadius : '50%'}} alt=""/>,
            key: "prescriptionUrl"
        },
        {
            title: 'Pharmacy name',
            render: (record) => <span>{record.pharmacyName ?record.pharmacyName : '-----'  } </span>,
            key: "Pharmacy"
        },
        
        // {
        //     title: 'Products',
        //     render: (record) => {
        //         return <div>
        //             {
        //                 record && record.nonMedicalProduct && record.nonMedicalProduct.length && record.nonMedicalProduct.map(item=>{
        //                     return <div className="gx-d-flex" style={{gap : 5}}>
        //                         <p>{item.productId}</p>
        //                         <p>{item.qty && item.qty}</p>
        //                     </div>
        //                 })
        //             }
        //         </div>
        //     },
        //     key: "Pharmacy"
        // },
        
        {
            title: 'status',
            render: (record) => <span className="gx-text-capitalize">{record.status} </span>,
            key: "status"
        },
        {
            title: '',
            render: (record) =>(record.status === 'quotationSent' || record.status === 'riderPicked' )? <TableAction> {renderActions(record)}</TableAction> : '',
            key: "action"
        },
    ];




    const renderActions = (record) => {
        
        return <div className="gx-d-flex gx-flex-column">
            
                
           {record.status === 'quotationSent' && <DeleteConfirm
                type="createOrder"
                recordName={'customerOrderList'}
                method="PUT"
                selectedItem={record}
                apiRequest="EditRecord"
                buttonName="Yes"
                cancelText={intlPlaceholder('confirmationModal.cancel')} 
                oKText={intlPlaceholder('confirmationModal.yes')}
                title={'are you sure?'}
                content={'you want to convert this into order?'}
                Icon={<span style={{ fontSize: '14px' }}>convert to order</span>}
            />}
            
           {record.status === 'riderPicked' && <DeleteConfirm
                type="completeOrder"
                recordName={'customerOrderList'}
                method="PUT"
                selectedItem={record}
                apiRequest="EditRecord"
                buttonName="Yes"
                cancelText={intlPlaceholder('confirmationModal.cancel')} 
                oKText={intlPlaceholder('confirmationModal.yes')}
                title={'are you sure?'}
                content={'you want to complete the order?'}
                Icon={<span style={{ fontSize: '14px' }}>complete</span>}
            />}
            

        </div>
    }


    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold gx-mb-3"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="pendingProduct.pageTitle" />
                    </h4>
                }
                extra={<div className="gx-d-flex gx-mb-0" style={{ flexWrap: 'wrap', gap: '10px' }}>
                    <div style={{ height: '100%' }} >
                        <SelectFilter
                            isShowingAll={true}
                            className="select-filter"
                            apiName="getOrder"
                            searchKey="category"
                            recordName="customerOrderList"
                            options={allCategories} placeholder={<IntlMessages id="pendingProduct.filter.filterByCategory" />} ItemValue="_id" label="name" />
                    </div>
                    <div style={{ width: '220px' }}>
                        <SearchBox placeholder={intlPlaceholder('pendingProduct.filter.ProductID')} recordName="customerOrderList" apiName="getOrder" requestType="FetchRecord" />
                    </div>
                    <AddOrderForm />
                </div>}
            >

                <OrderList scroll={{ x: 1400 }} recordName="customerOrderList" apiName="getOrder" columns={columns} />
            </Widget>
            <EditOrder />
        </Layout>
    );
};

export default Index;
import React, { useEffect, useState } from 'react'
import { DataRequestAction } from "redux/actions/CommonHttp";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from 'util/IntlMessages'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment'
import { Form, Row, Col, Select, Button, TimePicker, Spin } from 'antd';
const { Option } = Select;
const EditProfile = () => {

    const [customKey, setCustomKey] = useState([])
    const settingsProps = useSelector(state => state.auth)
    const { settingsData, updatingProfile } = settingsProps

    const curdProps = useSelector(state => state.CrudR)
    const { Edit_WorkingHour_Modal } = curdProps
    const dispatch = useDispatch();

    const toggleEditProfile = () => {
        if (settingsData.workingHour) {
            settingsData.workingHour && settingsData.workingHour.forEach(item => {
                if (item.to && item.from) {
                    item.to = moment(item.to)
                    item.from = moment(item.from)
                }

            })
        }
        dispatch({
            type: 'Show_Modal',
            payload: null,
            ModalName: 'Edit_WorkingHour_Modal',
            
        })
    }
    const closeModal = () => {
        
        dispatch({
            type: 'Hide_Modal',
            payload: null,
            ModalName: 'Edit_WorkingHour_Modal',
            
        })
    }

    const updateProfle = values => {

        settingsData.workingHour = values.workingHour
        console.log('=========updateprofile=======', settingsData)
        dispatch(DataRequestAction('PUT', 'updateProfile', 'updateProfile', settingsData, 'updatingProle', 'Edit_WorkingHour_Modal'))
    };

    const fetchTeamUsers = () => {

        if (settingsData && settingsData.workingHour) {
            let arr = []
            settingsData.workingHour.forEach((item, index) => {
                if (item.to && item.from) {
                    arr.push(index)
                }
            })
           
            setCustomKey(arr)

        }
    }
    useEffect(fetchTeamUsers, [Edit_WorkingHour_Modal])

    let days = [
        { value: 'monday', label: <IntlMessages id="settings.workingHourForm.monday" />, disabled: false },
        { value: 'tuesday', label: <IntlMessages id="settings.workingHourForm.tuesday" />, disabled: false },
        { value: 'wednesday', label: <IntlMessages id="settings.workingHourForm.wednesday" />, disabled: false },
        { value: 'thursday', label: <IntlMessages id="settings.workingHourForm.thursday" />, disabled: false },
        { value: 'friday', label: <IntlMessages id="settings.workingHourForm.friday" />, disabled: false },
        { value: 'saturday', label: <IntlMessages id="settings.workingHourForm.saturday" />, disabled: false },
        { value: 'sunday', label: <IntlMessages id="settings.workingHourForm.sunday" />, disabled: false },
    ]

    const handleChange = (value, key) => {

        if (!customKey.length && value === 'custom') {
            setCustomKey([key.key])
        }
        else if (customKey.length && value === 'custom') {
            let arr = [...customKey]
            arr.push(key.key)
            setCustomKey(arr)
        }
        else if (value !== 'custom') {
            if (customKey.includes(key.key)) {
                let newArray = []
                customKey && customKey.forEach(item => {
                    if (item !== key.key) {
                        newArray.push(item)

                    }
                })
                setCustomKey(newArray)
            }
        }
    }


    const renderDays = () => {
        return days && days.map(item => {
            return <Option value={item.value} key={item.value} disabled={item.disabled}>{item.label}</Option>
        })
    }
    console.log('==========customKey========', customKey)
    return (
        <div>

            <span style={{ color: 'grey' }} className="icon icon-edit gx-link" onClick={() => toggleEditProfile()}></span>
            <SimpleModal
                width={500}
                title={<span style={{ color: '#041649' }} className="gx-font-sans-bold">{<IntlMessages id="settings.editModal.updateProfile" />} </span>}
                modalFor="Edit_WorkingHour_Modal"
                visible={Edit_WorkingHour_Modal ? Edit_WorkingHour_Modal : false}>

                <span></span>
                <Form
                    layout="vertical"
                    name="editProfile"
                    onFinish={updateProfle}
                    initialValues={settingsData && settingsData}
                >



                    <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <label htmlFor="" className="gx-mb-2 gx-d-inline-block"><IntlMessages id="settings.workingHourForm.workingHours" /></label>
                            <Form.List name="workingHour" rules={[
                                {
                                    validator: async (_, workingHour) => {

                                        if (workingHour === undefined || workingHour.length < 1) {
                                            return Promise.reject(new Error('please select working hours'));
                                        }

                                    },
                                },
                            ]} >
                                {(fields, { add, remove }, { errors }) => (
                                    <> {fields && fields.map(item => {
                                        customKey && customKey.map(customItem => {
                                            
                                            if (item.fieldKey == customItem) {
                                                item.showField = true
                                            }
                                        })
                                    })}{
                                        console.log('============customItem')
                                    }
                                        {fields.map(({ key, name, fieldKey, showField, ...restField }) => (

                                            <>
                                                <Row className="gx-d-flex gx-flex-row">

                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">

                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'day']}
                                                            fieldKey={[fieldKey, 'day']}
                                                            rules={[{ required: true, message: <IntlMessages id="settings.workingHourForm.selectDay" /> }]}
                                                        >
                                                            <Select placeholder={<IntlMessages id="settings.workingHourForm.selectDay" />} >
                                                                {renderDays()}

                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pr-0">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'time']}
                                                            fieldKey={[fieldKey, 'time']}
                                                            rules={[{ required: true, message: <IntlMessages id="settings.workingHourForm.selectTime" /> }]}
                                                        >
                                                            <Select placeholder={<IntlMessages id="settings.workingHourForm.selectTime" />} onChange={(value, key) => { handleChange(value, key) }}>
                                                                <Option value="24" key={key}><IntlMessages id="settings.workingHourForm.24Hour" /></Option>
                                                                <Option value="off" key={key}><IntlMessages id="settings.workingHourForm.off" /></Option>
                                                                <Option value="custom" key={key}><IntlMessages id="settings.workingHourForm.custom" /></Option>

                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    {showField === true && <>
                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">

                                                            <Form.Item
                                                                {...restField}
                                                                label="Open"
                                                                name={[name, 'to']}
                                                                fieldKey={[fieldKey, 'to']}
                                                                rules={[{ required: true, message: <IntlMessages id="settings.workingHourForm.selectTime" /> }]}
                                                            >
                                                                <TimePicker format={'HH:mm'} className="gx-w-100" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pr-0">
                                                            <Form.Item
                                                                {...restField}
                                                                label="Close"
                                                                name={[name, 'from']}
                                                                fieldKey={[fieldKey, 'from']}
                                                                rules={[{ required: true, message: <IntlMessages id="settings.workingHourForm.selectTime" /> }]}
                                                            >
                                                                <TimePicker format={'HH:mm'} className="gx-w-100" />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                    }

                                                    <MinusCircleOutlined style={{ position: 'absolute', right: 0 }} onClick={() => remove(name)} />
                                                </Row>

                                            </>
                                        ))}
                                        <Form.Item className="gx-mb-0">
                                            <Button type="dashed" className="gx-pt-0 gx-mb-0" onClick={() => add()} block icon={<PlusOutlined />}>
                                                <IntlMessages id="settings.workingHourForm.addTimeAndDate" />
                                            </Button>
                                        </Form.Item>
                                        <Form.ErrorList errors={errors} />
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>



                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0 gx-mt-4">
                            <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">

                                <Form.Item className="gx-mb-0">
                                    <Button type="default" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() => closeModal()}>
                                        <IntlMessages id="settings.editModal.cancel" />
                                    </Button>
                                </Form.Item>
                                <Form.Item className="gx-mb-0">
                                    {updatingProfile && <Spin>   <Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="button" disbaled>
                                        <IntlMessages id="settings.editModal.save" />
                                    </Button></Spin>}

                                    {!updatingProfile && <Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="submit">
                                        <IntlMessages id="settings.editModal.save" />
                                    </Button>}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SimpleModal>
        </div>
    )
}

export default EditProfile

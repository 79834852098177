
import React from 'react'
import { Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux'


import { DataGetAction } from '../../redux/actions/http';
import { logoutFn } from 'redux/actions/Common'
const SimpleModal = () => {

        
    const authProps = useSelector(state => state.auth);
    const dispatch = useDispatch();


    const logoutUser = () =>{
        dispatch(DataGetAction('logout', '', 'loggingOut'));
        dispatch(logoutFn());
        // dispatch(logoutFn());
    }

    return (
        <Modal
            visible={authProps.unAuthorized_modal}
            centered
            footer={false}
            maskClosable={false}
            closable={false}
        >

            <div className="gx-text-center">
                <h2>Your session expired. Kindly login again.</h2>
                <Button className="gx-mt-3 gx-pt-0" onClick={() => {
                    dispatch({
                        type: "UN_AUTHORIZED_close"
                    });
                    logoutUser()
                }}>Login</Button>
            </div>

        </Modal>

    )
}

export default SimpleModal

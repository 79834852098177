import React, { useEffect } from "react";
import Widget from "components/Widget";
import ProductList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import TableAction from 'components/Table/Actions'
import Layout from 'components/LayoutForPortal'
import SelectFilter from 'components/Filters/SelectFilter'
import SearchBox from 'components/Filters/SearchBox'
import AddNewProductForm from './components/AddNewProductForm'
import { useDispatch, useSelector } from 'react-redux'
import EditProduct from './components/EditProduct'
import { intlPlaceholder } from 'util/intlPlaceholder'
import {  DataGetAction } from 'redux/actions/CommonHttp';
import { renderStatus } from 'utils/commonUseFunction'
import dummy from 'assets/images/user-dummy.png'
import moment from 'moment'
import DeleteConfirm from 'components/Modal/DeleteConfirm';
const Index = () => {
    let currency = process.env.REACT_APP_CURRENCY
    const dispatch = useDispatch()
    const fetchList = () => {

        dispatch(DataGetAction('getCategory', 'getCategory', { query: 'all' }, '', '', 'allCategories'))

    }

    useEffect(fetchList, [])


    const pageProps = useSelector(state => state.pageInfo)
    const { allCategories } = pageProps
    const columns = [
        {
            title: <IntlMessages id="product.id" />,
            render: (record) => <span>{record.productId}</span>,
            key: "id",
        },

        {
            title: <IntlMessages id="product.productName" />,
            render: (record) => {
                return <div className="gx-d-flex gx-align-items-center">
                    {record.productImageUrl ? <img alt="" src={record.productImageUrl} width='30' style={{ borderRadius: '50%' }} height='30' /> : <img src={dummy} width='30' style={{ borderRadius: '50%' }} height='30' alt="productImage" />}
                    <span className="gx-ml-2">{record.productName && record.productName}</span> </div>
            },
            key: "doctorName",
        },

        {
            title: <IntlMessages id="product.category" />,
            render: (record) => <span>{record.categoryName}</span>,
            key: "category"
        },
        {
            title: <IntlMessages id="product.manufacturer" />,
            render: (record) => <span>{record.manufacturer}</span>,
            key: "manufacturer"
        },
        {
            title: <IntlMessages id="product.pharmacyName" />,
            render: (record) => <span>{record.pharmacyName} </span>,
            key: "pharmacyName"
        },
        {
            title: <IntlMessages id="product.price" />,
            render: (record) => <span>{currency}{record.price && record.price} </span>,
            key: "price"
        },
        {
            title: <IntlMessages id="product.status" />,
            render: (record) => <span className="gx-text-capitalize">{renderStatus(record.status)} </span>,
            key: "location"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },
    ];





    const renderEdit = (record) => {
        record.dateAndTime = moment(record.dateAndTime)
        dispatch({

            type: 'Show_Modal',
            payload: record,
            ModalName: "Edit_Product_Modal"
        })

    }
    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
             <span className="action-link first gx-link" onClick={() => renderEdit(record)}><IntlMessages id="product.actions.edit" /></span>
            <DeleteConfirm
                type="deleteProduct"
                recordName={'productList'}
                method="DELETE"
                selectedItem={record}
                apiRequest="DeleteRecord"
                buttonName="Yes"
               cancelText={intlPlaceholder('confirmationModal.cancel')} 
                oKText={intlPlaceholder('confirmationModal.yes')}
                title={intlPlaceholder('product.actions.delete_text')}
                content={intlPlaceholder('product.actions.delete_content')}
                Icon={<span style={{ fontSize: '14px' }}><IntlMessages id="product.actions.delete" /></span>}
            />

        </div>
    }


    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold gx-mb-3"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="pendingProduct.pageTitle" />
                    </h4>
                }
                extra={<div className="gx-d-flex gx-mb-0" style={{ flexWrap: 'wrap' }}>
                    <div style={{ height: '100%', marginRight : '10px' }} >
                        <SelectFilter
                            isShowingAll={true}
                            className="select-filter"
                            apiName="getProduct"
                            searchKey="category"
                            recordName="productList"
                            options={allCategories} placeholder={<IntlMessages id="pendingProduct.filter.filterByCategory" />} ItemValue="_id" label="name" />
                    </div>
                    <div style={{ width: '220px', marginRight : '10px' }} >
                        <SearchBox placeholder={intlPlaceholder('pendingProduct.filter.ProductID')} recordName="productList" apiName="getProduct" requestType="FetchRecord" />
                    </div>
                    <AddNewProductForm />
                </div>}
            >

                <ProductList scroll={{ x: 1400 }} recordName="productList" apiName="getProduct" columns={columns} />
            </Widget>
            <EditProduct />
        </Layout>
    );
};

export default Index;
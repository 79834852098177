import React, { useEffect, useState } from 'react'
import { Form, Input, Row, Col, Select, InputNumber, Button ,notification} from 'antd';
import IntlMessages from 'util/IntlMessages'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/CommonHttp'
import AvatarUploader from 'components/AvatarUploader'
import PhoneInput from 'react-phone-input-2'
import { MinusCircleOutlined, PlusOutlined,IssuesCloseOutlined } from '@ant-design/icons';
const { Option } = Select;


const ProductForm = ({ setPhotoState, photoList, setIsProductSelected, setIsPrescriptionSelected,isPrescriptionSelected,isProductSelected }) => {


    const dispatch = useDispatch()

    const crudProps = useSelector(state => state.CrudR)
    const { allPharmacies, allProducts } = crudProps
    const [phoneNumber, setPhoneNumber] = useState();
    const [buttonState, setButtonState] = useState(true);



    const fetchList = () => {

        dispatch(DataGetAction('getPharmacyDropdown', 'FetchRecord', { query: 'all' }, '', '', 'allPharmacies'))
        dispatch(DataGetAction('getCategory', 'FetchRecord', { query: 'all' }, '', '', 'allCategories'))

    }

    useEffect(fetchList, [])


    const handleChange = (value) => {
        console.log(`selected ${value}`);
        if (value) {
            dispatch(DataGetAction('getPharmacyProduct', 'FetchRecord', { query: 'all', pharmacyId: value }, '', '', 'allProducts'))
        }

    }

    const openNotification = () => {
        notification.open({
          message: 'Error',
          description:
            'please either add product or upload prescription',
          icon: <IssuesCloseOutlined style={{ color: 'red' }} />,
        });
      };
    return (
        <div>

            <Row className="gx-mx-0 gx-d-flex gx-flex-row gx-align-items-center">
                <Col xl={14} lg={16} md={16} sm={24} xs={24} className="gx-pl-0">
                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name="fullName"
                                label='Name'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Name'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name="email"
                                label='Email'
                                rules={[
                                    {
                                        required: true,
                                        message: 'email'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xl={10} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                        name="photo"
                    >
                        <AvatarUploader action="customerForm" setIsPrescriptionSelected={setIsPrescriptionSelected} text={<IntlMessages id="customer.addProductModal.uploadProduct" />} setPhotoState={setPhotoState} photoList={photoList} />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label={<IntlMessages id="employee.addEmployeeModal.phone" />}
                        name="phoneNumber"
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px'
                        }}
                        rules={[{ required: true, message: <IntlMessages id="employee.addEmployeeModal.phone" /> }]}
                    >
                        <PhoneInput
                            inputStyle={{
                                width: '100%'
                            }}
                            country={'fr'}
                            value={phoneNumber}
                            onChange={phone => setPhoneNumber({ phone })}
                        />
                    </Form.Item>
                </Col>

                <Col xl={12} lg={12} md={12} sm={24} xs={24}>

                    <Form.Item
                        label='Address'
                        name="address"
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px'
                        }}
                        rules={[{ required: true, message: <IntlMessages id="customer.addProductModal.manufacturer" /> }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>


            <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label='vitale card number'
                        name="vitaleCardNumber"
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px'
                        }}
                        rules={[{ required: true, message: 'required!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col xl={12} lg={12} md={12} sm={24} xs={24}>

                    <Form.Item
                        label='Insurance card number'
                        name="insuranceCardNumber"
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px'
                        }}
                        rules={[{ required: true, message: 'required!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label='Delivery Mode'
                        name="deliveryMode"
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px'
                        }}
                        rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select >
                            <Option value="selfPickup">Self pickup</Option>
                            <Option value="rider"> Rider</Option>
                        </Select>
                    </Form.Item>
                </Col>


            </Row>
            <Row>

                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        name="pharmacyId"
                        label={<IntlMessages id="product.addProductModal.pharmacy" />}
                        rules={[
                            {
                                required: true,
                                message: <IntlMessages id="product.addProductModal.pharmacy" />
                            },
                        ]}
                    >
                        <Select onChange={handleChange}>
                            {allPharmacies && allPharmacies.map(item => {
                                return <Option key={item._id} value={item._id}>{item.pharmacyName}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.List name="nonMedicalProduct"

                        rules={[
                            {
                                validator: async (_, workingHour) => {

                                    if (isPrescriptionSelected || isProductSelected) {
                                        Promise.resolve()
                                    }
                                    else if (!isPrescriptionSelected) {
                                        openNotification()
                                        return Promise.reject();
                                    }

                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <> {
                                fields && fields.length > 0 ? setButtonState(false) : setButtonState(true)


                            }{
                                    fields && fields.length > 0 ? setIsProductSelected(true) : setIsProductSelected(false)

                                }
                                {fields.map(({ key, name, fieldKey, ...restField }) => (

                                    <Row key={key} className="gx-mx-0 gx-d-flex gx-flex-row">
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">
                                            <Form.Item
                                                {...restField}
                                                label="Product Name"
                                                name={[name, 'productId']}
                                                fieldKey={[fieldKey, 'productId']}
                                                rules={[{ required: true, message: 'Missing Product' }]}
                                                style={{ width: '100%' }}
                                            >
                                                <Select onChange={handleChange} style={{ width: '100%' }}>

                                                    {allProducts && allProducts.length && allProducts.map(item => {
                                                        return <Option value={item._id}>{item.productName}</Option>
                                                    })}

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xl={9} lg={9} md={12} sm={24} xs={24} className="gx-pr-0">
                                            <Form.Item
                                                {...restField}
                                                label="QTY"
                                                name={[name, 'qty']}
                                                fieldKey={[fieldKey, 'aty']}
                                                rules={[{ required: true, message: 'Missing qty' }]}
                                                style={{ width: '100%' }}
                                            >
                                                <InputNumber style={{ width: '100%' }} placeholder="qty" />
                                            </Form.Item>

                                        </Col>
                                        <Col xl={3} lg={3} md={3} sm={24} xs={24} className="gx-pr-0">
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Col>

                                    </Row>
                                ))}
                                {buttonState && <> <Form.Item>
                                    <Button type="dashed" className="gx-pt-0" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Products
                                    </Button>
                                </Form.Item>
                                    <Form.ErrorList errors={errors} /> </>
                                }
                            </>
                        )}
                    </Form.List>
                </Col>

            </Row>

        </div>
    )
}

export default ProductForm





import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { Spin, Button, Modal } from "antd";
import IntlMessages from "util/IntlMessages";
const VitalCardModal = ({ insuranceCardNumber }) => {
  const dispatch = useDispatch();

  const pageProps = useSelector((state) => state.pageInfo);
  const { cardLink, startLoader, insuranceModal } = pageProps;

  console.log("=========startLoader", startLoader);
  console.log("=========cardLink", startLoader);
  const fetchImage = () => {
    if (insuranceModal && insuranceCardNumber) {
      dispatch(
        DataRequestAction(
          "POST",
          "getTempLink",
          "getTempLink",
          { image: insuranceCardNumber },
          "StartLoader"
        )
      );
    }
  };
  useEffect(fetchImage, [insuranceModal]);
  const showCard = () => {
    dispatch({
      type: "Toggle_Insurance_Modal",
      payload: insuranceCardNumber,
    });
  };

  const handleCancel = () => {
    dispatch({
      type: "Toggle_Insurance_Modal",
      payload: null,
    });
  };

  return (
    <div>
      <Button
        type="primary"
        className="gx-pt-0 gx-mb-0 gx-mr-2"
        htmlType="button"
        onClick={() => showCard(insuranceCardNumber)}
      >
        <IntlMessages id="newOrder.addNewOrderModal.cardInsurance" />
      </Button>
      <Modal
        onCancel={handleCancel}
        width={500}
        visible={insuranceModal}
        footer={false}
      >
        {insuranceCardNumber ? (
          <>{startLoader ? <Spin /> : <img src={cardLink} alt="" />}</>
        ) : (
          <span>
            {" "}
            <IntlMessages id="imageModal.noDataFound" />
          </span>
        )}
      </Modal>
    </div>
  );
};

export default VitalCardModal;

import React, {  useEffect } from 'react'
import { Form, Input, Row, Col, Select,InputNumber } from 'antd';
import IntlMessages from 'util/IntlMessages'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/CommonHttp'
import AvatarUploader from 'components/AvatarUploader'


const { Option } = Select;


const ProductForm = ({ setPhotoState, photoList }) => {

    
    const dispatch = useDispatch()
    
    const crudProps = useSelector(state => state.CrudR)
    const { allCategories} = crudProps

    


    const fetchList = () => {

        dispatch(DataGetAction('getPharmacyDropdown', 'FetchRecord', { query: 'all' }, '', '', 'allPharmacies'))
        dispatch(DataGetAction('getCategory', 'FetchRecord', { query: 'all' }, '', '', 'allCategories'))

    }

    useEffect(fetchList, [])
    // function disabledDate(current) {
        
    //     return current && current < moment().endOf('day');
    // }
    return (
        <div>

            <Row className="gx-mx-0 gx-d-flex gx-flex-row gx-align-items-center">
                <Col xl={14} lg={16} md={16} sm={24} xs={24} className="gx-pl-0">
                <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="productName"
                        label={<IntlMessages id="product.addProductModal.productName" />}
                        rules={[
                            {
                                required: true,
                                message: <IntlMessages id="product.addProductModal.productName" />
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    </Col>
                    
                    </Row>
                    

                    <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="categoryId"
                        label={<IntlMessages id="product.addProductModal.category" />}
                        rules={[
                            {
                                required: true,
                                message: <IntlMessages id="product.addProductModal.category" />
                            },
                        ]}
                    >
                        <Select  >
                            {allCategories && allCategories.map(item=>{
                                return <Option key={item.id} value={item._id}>{item.name}</Option>
                            })}


                        </Select>
                    </Form.Item>
                    </Col>
                    
                    </Row>
                </Col>
                <Col xl={10} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item
                        name="photo"
                    >
                        <AvatarUploader text={<IntlMessages id="product.addProductModal.uploadProduct" />} setPhotoState={setPhotoState} photoList={photoList} />
                    </Form.Item>
                </Col>

            </Row>



            <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        name="price"
                        label={<IntlMessages id="product.addProductModal.price" />}
                        rules={[
                            {
                                required: true,
                                message: <IntlMessages id="product.addProductModal.price" />
                            },
                        ]}
                    >
                        <InputNumber style={{width : '100%'}} precision={2}/>
                    </Form.Item>
                </Col>
                
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>

                    <Form.Item
                        label={<IntlMessages id="product.addProductModal.manufacturer" />}
                        name="manufacturer"
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px'
                        }}
                        rules={[{ required: true, message: <IntlMessages id="product.addProductModal.manufacturer" /> }]}
                    >
                        <Input />
                    </Form.Item>
                    </Col>
            </Row>

        </div>
    )
}

export default ProductForm




